import request from '../utility/request';
import generateRequestURL from '../utility/generate-request-url';
import errors from '../errors/errors';

// eslint-disable-next-line import/extensions
import auth from '@/env/auth';

let apiURL = 'https://gateway.joinflows.com';

if (process.env.NODE_ENV === 'development') {
  apiURL = 'http://localhost:3000';
}

export default (path, options = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      // get id token
      const idToken = await auth.getIdToken();

      // generate request url
      const requestURL = generateRequestURL(`${apiURL}${path}`, options);

      // make request
      let response = null;

      // GET Request
      if (options.method === 'GET') {
        response = await request.get(requestURL, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
      }

      // POST Request
      if (options.method === 'POST') {
        response = await request.post(requestURL, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json',
          },
          ...options,
        });
      }

      // PATCH Request
      if (options.method === 'PATCH') {
        response = await request.patch(requestURL, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json',
          },
          ...options,
        });
      }

      // DELETE Request
      if (options.method === 'DELETE') {
        response = await request.delete(requestURL, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json',
          },
          ...options,
        });
      }

      resolve(response);
    } catch (error) {
      console.log('Failed to make request', error);
      reject(errors.getAPIError(error));
    }
  });
};
