import { createRouter, createWebHistory } from '@ionic/vue-router';

import Tabs from '@/views/Tabs.vue';

export default createRouter({
  history: createWebHistory('/'),
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/a/',
      component: Tabs,
      children: [
        {
          path: '/',
          redirect: '/today',
        },
        {
          path: '/today',
          name: 'Today',
          component: () => import('@/views/Today/index.vue'),
        },
        {
          path: '/discover',
          name: 'Discover',
          component: () => import('@/views/Discover/index.vue'), //
        },
        {
          path: '/profile',
          name: 'Profile',
          component: () => import('@/views/Profile/index.vue'),
        },
        {
          path: '/settings/profile',
          name: 'SettingsProfile',
          component: () => import('@/views/SettingsProfile/index.vue'),
          props: {
            showNav: false,
          },
        },
        {
          path: '/settings/notifications',
          name: 'SettingsNotifications',
          component: () => import('@/views/SettingsNotifications/index.vue'),
          props: {
            showNav: false,
          },
        },
        {
          path: '/settings/subscriptions',
          name: 'SettingsSubscriptions',
          component: () => import('@/views/SettingsSubscriptions/index.vue'),
          props: {
            showNav: false,
          },
        },
        {
          path: '/flow/:flowId',
          name: 'FlowDetail',
          component: () => import('@/views/FlowDetail/index.vue'),
          props: {
            showNav: false,
          },
        },
        {
          path: '/flow/:flowId/setup',
          name: 'FlowSetup',
          component: () => import('@/views/FlowSetup/index.vue'),
          props: {
            showNav: false,
          },
        },
        {
          path: '/onboarding',
          name: 'Onboarding',
          component: () => import('@/views/Onboarding/index.vue'),
          props: {
            showNav: false,
          },
        },
        {
          path: '/onboarding/auth',
          name: 'OnboardingAuth',
          component: () => import('@/views/OnboardingAuth/index.vue'),
          props: {
            showNav: false,
          },
        },
        {
          path: '/imprint',
          name: 'Imprint',
          component: () => import('@/views/static/Imprint/index.vue'),
          props: {
            showNav: false,
          },
        },
        {
          path: '/privacy',
          name: 'Privacy',
          component: () => import('@/views/static/Privacy/index.vue'),
          props: {
            showNav: false,
          },
        },
        {
          path: '/terms-of-use',
          name: 'TermsOfUse',
          component: () => import('@/views/static/TermsOfUse/index.vue'),
          props: {
            showNav: false,
          },
        },
        {
          path: '/license-info',
          name: 'LicenseInfo',
          component: () => import('@/views/static/LicenseInfo/index.vue'),
          props: {
            showNav: false,
          },
        },
        {
          path: '/subscription-checkout-successful',
          name: 'SubscriptionCheckoutSuccessful',
          component: () => import('@/views/SubscriptionCheckoutSuccessful/index.vue'),
          props: {
            showNav: false,
          },
        },
        {
          path: '/subscription-checkout-canceled',
          name: 'SubscriptionCheckoutCanceled',
          component: () => import('@/views/SubscriptionCheckoutCanceled/index.vue'),
          props: {
            showNav: false,
          },
        },
      ],
    },
    // {
    //   path: '*',
    //   name: 'ErrorNotFound',
    //   component: () => import('../views/ErrorNotFound.vue'),
    // },
  ],
});
