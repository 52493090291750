<template>
  <div class="ui-content" :class="{
    'limit-width': limitWidth,
    'small-width': smallWidth,
    'wide-width': wideWidth,
    'safe-area-top': safeAreaTop,
    'safe-area-bottom': safeAreaBottom,
    'no-padding': noPadding,
    'no-bottom-padding': noBottomPadding,
    'no-top-padding': noTopPadding,
    'intro': intro,
    'card': card,
  }">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'UIContent',
  components: {
  },
  props: {
    limitWidth: {
      type: Boolean,
      default: false,
    },
    smallWidth: {
      type: Boolean,
      default: false,
    },
    wideWidth: {
      type: Boolean,
      default: false,
    },
    safeAreaTop: {
      type: Boolean,
      default: false,
    },
    safeAreaBottom: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    noBottomPadding: {
      type: Boolean,
      default: false,
    },
    noTopPadding: {
      type: Boolean,
      default: false,
    },
    intro: {
      type: Boolean,
      default: false,
    },
    card: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.ui-content {
  width: 100%;
  padding: var(--default-content-padding);
}

.ui-content.card {
  padding: var(--default-content-padding) !important;
}

.ui-content.limit-width {
  max-width: var(--default-content-max-width);
  margin-left: auto;
  margin-right: auto;
}

.ui-content.limit-width.small-width {
  max-width: 550px !important;
}

.ui-content.limit-width.wide-width {
  max-width: 900px !important;
}

.ui-content.safe-area-top {
  margin-top: calc(var(--safe-area-top) + 2.8em);
}

.ui-content.safe-area-bottom {
  margin-bottom: calc(var(--safe-area-bottom) + 4em);
}

.ui-content.no-padding {
  padding: 0em !important;
}

.ui-content.no-bottom-padding {
  padding-bottom: 0em !important;
}

.ui-content.no-top-padding {
  padding-top: 0em !important;
}

/* SPACING FOR LARGER DEVICES */
@media (min-width: 800px) {
  .ui-content:not(.card) {
    padding-left: 4em !important;
    padding-right: 4em !important;
  }

  .ui-content.card {
    padding: var(--default-content-padding) !important;
  }

  .ui-content.limit-width {
    max-width: calc(var(--default-content-max-width) + 8em);
  }
}

.ui-content.intro {
  padding-top: 2em;
}
</style>
