<template>
  <img src="../../assets/img/logos/signature-flows-logo-color.svg" class="logo-flows-full" draggable="false">
</template>

<script>
export default {
  name: 'LogoFlowsFull',
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.logo-flows-full {
  width: 100%;
  max-width: 6.4em;
}
</style>
