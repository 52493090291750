export default {
  get: (route, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(route, {
          headers: options?.headers,
        });

        const data = response.json();

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  post: (route, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        let body = {};

        if (typeof options?.body === 'object') {
          body = JSON.stringify(options.body);
        }

        const response = await fetch(route, {
          method: 'POST',
          mode: 'cors',
          headers: options?.headers,
          body,
        });

        const data = response.json();

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  patch: (route, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        let body = {};

        if (typeof options?.body === 'object') {
          body = JSON.stringify(options.body);
        }

        const response = await fetch(route, {
          method: 'PATCH',
          mode: 'cors',
          headers: options?.headers,
          body,
        });

        const data = response.json();

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  delete: (route) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(route, {
          method: 'DELETE',
          mode: 'cors',
          headers: options?.headers,
        });

        const data = response.json();

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
