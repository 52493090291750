<template>
  <div class="app-sidebar-item" :class="{
      'state-active': tabActive,
    }">
    <ion-nav-link :routerLink="link" router-direction="root" class="sidebar-item">
      <div class="icon">
        <slot/>
      </div>
      <div class="label">{{ label }}</div>
    </ion-nav-link>
  </div>
</template>

<script>
import { IonNavLink } from '@ionic/vue';

export default {
  name: 'AppSidebarItem',
  components: {
    IonNavLink,
  },
  props: {
    link: {
      type: String,
      default: '/',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tabActive: false,
    };
  },
  watch: {
    $route(route) {
      this.setTabState(route.path, this.link);
    },
  },
  methods: {
    setTabState(selectedRoute, tabRoute) {
      if (selectedRoute === tabRoute) {
        this.tabActive = true;
      } else {
        this.tabActive = false;
      }
    },
  },
  created() {
    this.setTabState(this.$route.path, this.link);
  },
};
</script>

<style scoped>
.app-sidebar-item {
  font-size: var(--font-size-small);
  cursor: pointer;
  border-radius: 0.55em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 1.2em;
  padding-right: 1.2em;
  transition: 0.1s ease-in-out;
}

.app-sidebar-item.state-active {
  background: var(--color-background-tertiary);
}

.sidebar-item {
  display: flex;
}

.icon {
  margin-right: 0.8em;
  font-size: 1em;
  color: var(--color-primary);
}
</style>
