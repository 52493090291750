<template>
  <div class="app-sidebar">
    <div class="sidebar-logo">
      <logo-flows-full />
    </div>
    <div class="sidebar-content">
      <app-sidebar-item :label="$t('tabNav.today')" link="/today" class="sidebar-item">
        <icon-stream />
      </app-sidebar-item>

      <app-sidebar-item :label="$t('tabNav.discover')" link="/discover" class="sidebar-item">
        <icon-search />
      </app-sidebar-item>

      <app-sidebar-item :label="$t('tabNav.profile')" link="/profile" class="sidebar-item">
        <icon-user />
      </app-sidebar-item>
    </div>
  </div>
</template>

<script>
import LogoFlowsFull from '@/components/logos/LogoFlowsFull.vue';
import AppSidebarItem from '@/components/layout/AppSidebarItem.vue';

import IconSearch from '@/components/icons/IconSearch.vue';
import IconStream from '@/components/icons/IconStream.vue';
import IconUser from '@/components/icons/IconUser.vue';

export default {
  name: 'AppSidebar',
  components: {
    LogoFlowsFull,
    AppSidebarItem,
    IconSearch,
    IconStream,
    IconUser,
  },
  props: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
  created() {
  },
};
</script>

<style scoped>
.app-sidebar {
  position: fixed;
  top: var(--default-content-padding);
  left: var(--default-content-padding);
  width: 200px;
  height: calc(100vh - (var(--default-content-padding) * 2));
  z-index: 2000;
  background: var(--color-background-secondary);
  border: 1px solid var(--color-card-outline-secondary);
  border-radius: 1.2em;
}

.sidebar-logo {
  padding-top: var(--default-content-padding);
  padding-left: var(--default-content-padding);
  padding-right: var(--default-content-padding);
  margin-bottom: 2em;
}

.sidebar-content {
  padding: 0.8em;
}

.sidebar-item {
  margin-bottom: 0.55em;
}

.sidebar-item:last-child {
  margin-bottom: 0em;
}
</style>
