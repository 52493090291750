<template>
  <div class="tab" :class="{
      'state-active': tabActive,
    }">
    <ion-nav-link :routerLink="link" router-direction="root">
      <div class="icon">
        <slot/>
      </div>
      <div class="label">{{ label }}</div>
    </ion-nav-link>
  </div>
</template>

<script>
import { IonNavLink } from '@ionic/vue';

export default {
  name: 'AppTabNavTab',
  components: {
    IonNavLink,
  },
  props: {
    link: {
      type: String,
      default: '/',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tabActive: false,
    };
  },
  watch: {
    $route(route) {
      this.setTabState(route.path, this.link);
    },
  },
  methods: {
    setTabState(selectedRoute, tabRoute) {
      if (selectedRoute === tabRoute) {
        this.tabActive = true;
      } else {
        this.tabActive = false;
      }
    },
  },
  created() {
    this.setTabState(this.$route.path, this.link);
  },
};
</script>

<style scoped>
.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: 0.2s;
  cursor: pointer;
  color: var(--color-text);
}

.tab.state-active {
  color: var(--color-primary);
}

.icon {
  font-size: 1em;
}

.label {
  font-size: 0.85em;
  white-space: nowrap;
}

ion-nav-link {
  width: 100%;
}
</style>
