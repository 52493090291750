import { defineStore } from 'pinia';

import userApi from '@/api/user';
import auth from '@/env/auth';
// import User from '@/models/User';

const useUserStore = defineStore('UserStore', {
  state() {
    return {
      user: {
        userId: '',
        loading: true,
        uid: '',
        type: '',
        status: '',
        picture: '',
        displayName: '',
        firstName: '',
        lastName: '',
        email: '',
        authMethods: {
          email: {
            active: true,
            verified: false,
          },
        },
        privacy: {
          allowAdvancedTracking: false,
        },
        primaryLanguage: '',
        primaryLocale: '',
        isClubMember: false,
        isClubPlusMember: false,
        isSignedIn: false,
        isFirstUsage: false,
        membershipExpirationDate: '',
        lastLogin: '',
        created: '',
      },
    };
  },
  actions: {
    async getUser() {
      let user = {};

      // when id token is provided, lookup user
      userApi.getUser()
        .then((userData) => {
          // set status
          user = userData?.data;

          console.log('got user', userData);

          user.isSignedIn = true;

          this.user = user;
        })
        .catch(async (error) => {
          console.log('there was an error', error);

          // couldn't find user, sign out
          await auth.signOut();

          user = {
            isSignedIn: false,
          };

          this.user = user;
        });
    },
    clearUser() {
      const user = {
        isSignedIn: false,
      };

      this.user = user;
    },
    updateUserInterests({ interests }) {
      // when id token is provided, update interests
      userApi.updateInterests({
        interests,
      })
        .then((result) => {
          console.log('INTERESTS UPDATED SUCCESSFULLY', result);
        })
        .catch(async (error) => {
          console.log('Failed to update interests', error);
        });
    },
  },
  getters: {
    // user: () => this.user,
  },
});

export default useUserStore;
