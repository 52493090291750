export default (url, options = {}) => {
  let output = '';
  let inputURL = url;

  output = new URL(inputURL);

  // params
  if (options?.params) {
    output.pathname = output.pathname.replace(/:\w+/g, (param) => {
      let replacement = '';
      let paramReplacement = param.split(':')[1];

      replacement = options.params[paramReplacement];

      return replacement;
    });
  }

  // query parameters
  if (options?.query) {
    for (let key in options.query) {
      output.searchParams.append(transformQueryParameter(key, options?.transformQuery), options.query[key]);
    }
  }

  return output.href;
};

function transformQueryParameter(param, type) {
  let output = '';

  if (type === 'kebab-case') {
    output = toKebabCase(param);
  } else {
    output = param;
  }

  return output;
}

function toKebabCase(value) {
  let output = value.replace(/[A-Z]/g, '-$&').toLowerCase();

  return output;
}
