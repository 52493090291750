<template>
  <div class="ui-glass">
    <div class="frost"></div>
    <div class="background"></div>
    <div class="background-gradient"></div>
  </div>
</template>

<script>
export default {
  name: 'UIGlass',
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.ui-glass {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -3;
  backdrop-filter: blur(1rem);
}

.frost {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  opacity: 1;
  background-image: url('../../assets/img/glass/frost.png');
  background-repeat: repeat;
  background-size: 5em;
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
}

.background-gradient {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: var(--gradient-glass-primary);
}
</style>
