<template>
  <div class="app-offline-overlay" :class="!connected ? 'show' : ''">
    <div class="inner">
      <ui-content>
        <h3>{{ $t('overlays.offline.title') }}</h3>
        <p v-html="$t('overlays.offline.description')"></p>
      </ui-content>
    </div>
  </div>
</template>

<script>
import { Network } from '@capacitor/network';

import UiContent from '@/components/ui/UIContent.vue';

export default {
  name: 'AppOfflineOverlay',
  components: {
    UiContent,
  },
  props: {
  },
  data() {
    return {
      connected: true,
    };
  },
  methods: {
  },
  created() {
    Network.addListener('networkStatusChange', (status) => {
      this.connected = status.connected;
    });
  },
};
</script>

<style scoped>
.app-offline-overlay {
  /* background: linear-gradient(209deg, var(--color-background-secondary), var(--color-background-secondary-2)); */
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(1em);
    backdrop-filter: blur(1em);
}

.app-offline-overlay.show {
  pointer-events: auto;
  opacity: 1;
}
</style>
