import { IonicVue } from '@ionic/vue';
import { SplashScreen } from '@capacitor/splash-screen';
import { DisableSwipeBackDirective } from 'v-disable-swipe-back';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from '@/App.vue';
import '@/registerServiceWorker';
import router from '@/router';
// import store from './store';
import i18n from '@/i18n';

/* Core CSS required for Ionic components to work properly */
// import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
// import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
// import '@ionic/vue/css/padding.css';
// import '@ionic/vue/css/float-elements.css';
// import '@ionic/vue/css/text-alignment.css';
// import '@ionic/vue/css/text-transformation.css';
// import '@ionic/vue/css/flex-utils.css';
// import '@ionic/vue/css/display.css';

const pinia = createPinia();

const app = createApp(App)
  .use(router)
  // .use(store)
  .use(i18n)
  .use(pinia)
  .use(IonicVue, {
    rippleEffect: false,
    mode: 'ios',
  })
  .directive('disable-swipe-back', DisableSwipeBackDirective);

router.isReady().then(() => {
  app.mount('#app');
});

router.beforeEach(() => {
  window.scrollTo(0, 0);
});

document.addEventListener('touchstart', () => {

}, {
  passive: true,
});

// hide splash screen on load to avoid white screen
window.addEventListener('load', () => {
  setTimeout(async () => {
    SplashScreen.hide();
  }, 250);
});
