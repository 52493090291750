// AUTHENTICATE USING FIREBASE AUTH/NATIVE AUTH
// import { Capacitor } from '@capacitor/core';
import { initializeApp } from 'firebase/app';
import {
  // getAuth,
  initializeAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  setPersistence,
  browserLocalPersistence,
  onAuthStateChanged,
} from 'firebase/auth';

import userApi from '@/api/user';

const firebaseConfig = {
  apiKey: 'AIzaSyAJJHRlV71yfb464fyIM9Yh2xFQMKtW8gM',
  authDomain: 'signature-53ef2.firebaseapp.com',
  projectId: 'signature-53ef2',
  storageBucket: 'signature-53ef2.appspot.com',
  messagingSenderId: '361180814872',
  appId: '1:361180814872:web:9aae0c7f87af6fe79b6d6d',
};

// Initialize Firebase
let app = null;
let auth = null;

let tempIdToken = '';

export default {
  async init(callback) {
    app = initializeApp(firebaseConfig);
    // auth = getAuth(app);
    auth = initializeAuth(app, {
      persistence: browserLocalPersistence,
    });

    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        // get id token
        user.getIdToken().then((idToken) => {
          callback({
            status: 'signed-in',
            user,
            idToken,
          });

          tempIdToken = idToken;
        });
      } else {
        // User is signed out
        callback({
          status: 'signed-out',
        });
      }
    });
  },
  async onAuthStateChanged() {
    return new Promise((resolve) => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in
          resolve(user);
        } else {
          // User is signed out
        }
      });
    });
  },
  async signInWithEmailAndPassword({ email, password }) {
    return new Promise((resolve, reject) => {
      setPersistence(auth, browserLocalPersistence)
        .then(() => {
          // sign in with email and password after the persistence mode was set
          signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              // Signed in
              const { user } = userCredential;

              resolve(user);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async signUpWithEmailAndPassword({
    email,
    password,
    firstName,
    lastName,
  }) {
    return new Promise((resolve, reject) => {
      setPersistence(auth, browserLocalPersistence)
        .then(() => {
          createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              // Signed in
              const { user } = userCredential;

              user.getIdToken().then((idToken) => {
                // next, use user API to create a new signature user and to send verification email
                userApi.createUser({
                  firstName,
                  lastName,
                  email,
                })
                  .then((userData) => {
                    resolve(userData);
                  });
              });
            })
            .catch((error) => {
              reject(error);
            });
        });
    });
  },
  async signOut() {
    return new Promise((resolve, reject) => {
      signOut(auth)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async getIdToken() {
    return new Promise((resolve) => {
      // request id token
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in
          // get id token
          user.getIdToken().then((idToken) => {
            resolve(idToken);

            tempIdToken = idToken;
          });
        } else {
          // User is signed out
          resolve(null);
        }
      });
    });
  },
  async getTempIdToken() {
    return tempIdToken;
  },
  async requestEmailVerificationCode({ idToken }) {
    return new Promise((resolve, reject) => {
      userApi.requestEmailVerificationCode({
        idToken,
      })
        .then((verificationCode) => {
          resolve(verificationCode);
        })
        .reject((error) => {
          reject(error);
        });
    });
  },
};
