import { createI18n } from 'vue-i18n';
import messages from '../lang/de-DE/de-DE';
import datetimeFormats from '../lang/de-DE/date-time-formats';
import numberFormats from '../lang/de-DE/number-formats';

const i18n = createI18n({
  locale: 'de-DE',
  fallbackLocale: 'de-DE',
  messages,
  datetimeFormats,
  numberFormats,
});

export default i18n;

// const loadedLanguages = ['de'];
