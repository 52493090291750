<template>
  <div class="app-tab-nav" :class="{ 'hidden': hidden, 'show': show }">
    <div class="tab-nav-wrapper">
      <div class="tab-nav">
        <div class="tabs">
          <app-tab-nav-tab :label="$t('tabNav.today')" link="/today" class="tab">
            <icon-stream/>
          </app-tab-nav-tab>
          <app-tab-nav-tab :label="$t('tabNav.discover')" link="/discover" class="tab">
            <icon-search/>
          </app-tab-nav-tab>
          <app-tab-nav-tab :label="$t('tabNav.profile')" link="/profile" class="tab">
            <icon-user/>
          </app-tab-nav-tab>
        </div>
        <div class="nav-background">
          <ui-glass/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Keyboard } from '@capacitor/keyboard';

import AppTabNavTab from '@/components/layout/AppTabNavTab.vue';
import UiGlass from '@/components/ui/UIGlass.vue';
import IconSearch from '@/components/icons/IconSearch.vue';
import IconStream from '@/components/icons/IconStream.vue';
import IconUser from '@/components/icons/IconUser.vue';
import env from '@/env';

export default {
  name: 'AppTabNav',
  components: {
    AppTabNavTab,
    UiGlass,
    IconSearch,
    IconStream,
    IconUser,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      hidden: false,
    };
  },
  methods: {
  },
  created() {
    if (env.platform !== 'web') {
      // hide tab nav when keyboard is expanded
      Keyboard.addListener('keyboardWillShow', () => {
        this.hidden = true;
      });

      Keyboard.addListener('keyboardWillHide', () => {
        setTimeout(() => {
          this.hidden = false;
        }, 100);
      });
    }
  },
};
</script>

<style scoped>
.app-tab-nav {
  position: fixed;
  bottom: calc(0.5em + var(--safe-area-bottom));
  left: 0em;
  height: 4em;
  width: 100%;
  z-index: 1001;
  transition: 0.19s;
  opacity: 0;
  pointer-events: none;
}

.hidden.app-tab-nav {
  opacity: 0;
  pointer-events: none;
}

.tab-nav-wrapper {
  padding-left: var(--default-content-padding);
  padding-right: var(--default-content-padding);
  max-width: var(--default-content-max-width);
  margin: auto;
  width: 100%;
}

.tab-nav {
  padding-top: 0.2em;
  display: block;
  position: relative;
  border-radius: 1.2rem;
  overflow: hidden;
  border: 1px solid var(--color-card-border);
  padding-left: 0.2em;
  padding-right: 0.2em;
}

.nav-background {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 1.1rem;
  overflow: hidden;
}

.tabs {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.tabs .tab {
  flex-basis: 33.3333%;
}

.app-tab-nav.show {
  opacity: 1;
  pointer-events: auto;
}
</style>
