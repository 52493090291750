<template>
  <ion-page :class="{ 'hide-sidebar': !showNav }">
    <app-sidebar class="large-only sidebar" />
    <ion-tabs :class="animateInClass" class="app-view">
      <ion-router-outlet id="tabs-router-outlet" :animated="platform !== 'web'"></ion-router-outlet>
      <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
      <app-tab-nav :show="showNav" class="small-only"/>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { ref } from 'vue';
import {
  IonTabs,
  IonPage,
  IonRouterOutlet,
} from '@ionic/vue';
import AppTabNav from '@/components/layout/AppTabNav.vue';
import AppSidebar from '@/components/layout/AppSidebar.vue';
import env from '@/env';

export default {
  name: 'Tabs',
  components: {
    AppTabNav,
    AppSidebar,
    IonTabs,
    IonPage,
    IonRouterOutlet,
  },
  data() {
    return {
      showNav: false,
      animateIn: false,
      animateInClass: '',
    };
  },
  watch: {
    $route() {
      this.updateNavLayout();
      this.applyAnimations();
    },
  },
  computed: {
    platform() {
      return env.platform;
    },
  },
  methods: {
    updateNavLayout() {
      // updates the navigation layout (show tab nav, hide tab nav, etc.) based on route parameters
      // route props are "hidden" inside an matched array
      // first item is the /a route, second the route with the needed info
      if (this.$route.matched.length === 2) {
        const route = this.$route.matched[1];
        // option: Hide Tab Nav
        if (route.props.default) {
          if (route.props.default.showNav !== undefined) {
            this.showNav = route.props.default.showNav;
          } else {
            this.showNav = true;
          }
        } else {
          // no props defined, use default values specified below
          this.showNav = true;
        }
      }
    },
    applyAnimations() {
      let animateIn = null;

      if (!this.$route.params) {
        return;
      }

      if (this.$route.params.animateIn !== undefined) {
        animateIn = this.$route.params.animateIn;
      }

      if (animateIn) {
        this.animateIn = true;
        this.animateInClass = animateIn;
      } else {
        this.animateIn = false;
        this.animateInClass = '';
      }
    },
  },
  created() {
    // route changed
    // hide tab nav when specified on route
    this.applyAnimations();
    this.updateNavLayout();
  },
  setup() {
    const content = ref();

    const scrollToTop = () => {
      content.value.$el.scrollToTop(350);
    };

    return {
      content,
      scrollToTop,
    };
  },
};
</script>

<style scoped>
.app-view {
  max-width: calc(100vw - (200px + var(--default-content-padding)));
  margin-left: calc(200px + var(--default-content-padding));
  transition: 0.24s;
}

@media (max-width: 800px) {
  .app-view {
    max-width: 100vw !important;
    margin: 0em !important;
  }
}

.sidebar {
  transition: 0.4s;
  pointer-events: auto;
}

/* HIDE SIDEBAR */
.hide-sidebar .app-view {
  margin: 0em !important;
  max-width: 100vw !important;
}

.hide-sidebar .sidebar {
  opacity: 0;
  pointer-events: none;
}
</style>
