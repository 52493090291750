/* eslint-disable no-async-promise-executor */
import appRequest from '../utility/app-request';

export default {
  async getUser() {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await appRequest('/v1/me', {
          method: 'GET',
        });

        // resolve
        resolve(response);

      } catch (error) {
        reject(error);
      }
    });
  },
  async checkExistenceByEmail(email) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await appRequest('/v1/users/existence', {
          method: 'GET',
          query: {
            email,
          },
        });

        // resolve
        resolve(response);

      } catch (error) {
        reject(error);
      }
    });
  },
  async createUser(options) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await appRequest('/v1/users', {
          method: 'POST',
          body: options,
        });

        // resolve
        resolve(response);

      } catch (error) {
        reject(error);
      }
    });
  },
  requestEmailVerificationCode() {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await appRequest('/v1/me/email/verification', {
          method: 'POST',
        });

        // resolve
        resolve(response);

      } catch (error) {
        reject(error);
      }
    });
  },
  verifyEmailAddress(options) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await appRequest('/v1/me/email/verification', {
          method: 'PATCH',
          body: options,
        });

        // resolve
        resolve(response);

      } catch (error) {
        reject(error);
      }
    });
  },
  updateInterests({ idToken, interests }) {
    return new Promise(async (resolve, reject) => {
      // const response = await fetch(`${apiURL}/user/interests/update`, {
      //   method: 'POST',
      //   mode: 'cors',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({
      //     idToken,
      //     interests,
      //   }),
      // });

      // const interestsResult = await response.json();

      // resolve(interestsResult);
    });
  },
  getActiveFlow() {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await appRequest('/v1/joined-flows', {
          method: 'GET',
        });

        // resolve
        resolve(response);

      } catch (error) {
        reject(error);
      }
    });
  },
  cancelActiveFlow() {
    return new Promise(async (resolve, reject) => {
      try {
        const activeFlow = await appRequest('/v1/joined-flows', {
          method: 'GET',
          query: {
            status: 'active',
          },
        });

        if (!activeFlow?.data) {
          throw new Error('no-active-flow');
        }

        if (!activeFlow.data.length) {
          throw new Error('no-active-flow');
        }

        const response = await appRequest('/v1/joined-flows/:joinedFlowId/cancel', {
          method: 'PATCH',
          params: {
            joinedFlowId: activeFlow.data[0]?.joinedFlowId,
          },
          body: {
          },
        });

        // resolve
        resolve(response);

      } catch (error) {
        reject(error);
      }
    });
  },
  getActiveFlowProgress(options) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await appRequest('/v1/sessions', {
          method: 'GET',
          query: {
            status: 'completed',
          }
        });

        // resolve
        resolve(response);

      } catch (error) {
        reject(error);
      }
    });
    // return new Promise(async (resolve, reject) => {
    //   const query = {};

    //   if (items !== undefined) {
    //     query.items = items;
    //   }

    //   if (fromDate !== undefined) {
    //     const formattedFromDate = new Date(new Date(fromDate).setUTCHours(0, 0, 0, 0));

    //     query.fromDate = formattedFromDate.toISOString();
    //     console.log('FROM DATE', formattedFromDate.toISOString());
    //   }

    //   const queryString = new URLSearchParams(query).toString();
    //   const response = await fetch(`${apiURL}/user/flows/active/progress?${queryString}`, {
    //     method: 'POST',
    //     mode: 'cors',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({
    //       idToken,
    //     }),
    //   });

    //   const activeFlowProgressResult = await response.json();

    //   resolve(activeFlowProgressResult);
    // });
  },
  getTransferExercise(options) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await appRequest('/v1/joined-flows/:joinedFlowId/transfer-exercises/suggestion', {
          method: 'GET',
          params: {
            joinedFlowId: options?.joinedFlowId,
          },
        });

        // resolve
        resolve(response);

      } catch (error) {
        reject(error);
      }
    });
  },
  generateTransferExercise(options) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await appRequest('/v1/joined-flows/:joinedFlowId/transfer-exercises/suggestion', {
          method: 'GET',
          params: {
            joinedFlowId: options?.joinedFlowId,
          },
        });

        // resolve
        resolve(response);

      } catch (error) {
        reject(error);
      }
    });
  },
  completeTransferExercise(options) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await appRequest('/v1/joined-flows/:joinedFlowId/transfer-exercises/:transferExerciseId/to-do/complete', {
          method: 'POST',
          params: {
            joinedFlowId: options?.joinedFlowId,
            transferExerciseId: options?.transferExerciseId,
          },
        });

        // resolve
        resolve(response);

      } catch (error) {
        reject(error);
      }
    });
  },
  getRecommendedExercise(options) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await appRequest('/v1/joined-flows/:joinedFlowId/exercises/suggestion', {
          method: 'GET',
          params: {
            joinedFlowId: options?.joinedFlowId,
          },
        });

        // resolve
        resolve(response);

      } catch (error) {
        reject(error);
      }
    });
  },
  async getSubscriptions(options) {
    return new Promise(async (resolve, reject) => {
      try {
        // prepare body
        const body = {};

        const response = await appRequest('/v1/me/subscriptions', {
          method: 'GET',
          body,
        });

        // resolve
        resolve(response);

      } catch (error) {
        reject(error);
      }
    });
  },
};
