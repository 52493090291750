<template>
  <div class="app-preloader" :class="{ 'hide': hidden }">
  </div>
</template>

<script>

export default {
  name: 'AppPreloader',
  components: {
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    manualControl: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hidden: false,
    };
  },
  watch: {
    show() {
      this.hidden = !this.show;
    },
  },
  methods: {
  },
  created() {
    // show preloader only when selected
    if (this.show && !this.manualControl) {
      window.addEventListener('load', () => {
        this.hidden = true;
      });
    }
  },
};
</script>

<style scoped>
.app-preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
  background: #050505;
  transition: 0.29s;
  opacity: 1;
  pointer-events: auto;
}

.app-preloader.hide {
  opacity: 0;
  pointer-events: none;
}
</style>
